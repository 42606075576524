import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/react'
import Image from 'gatsby-image'
import { breakpoints, container } from '../../styles/theme'

const ShoesIntro = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(
        relativePath: { eq: "img/shoes/grey-suede-wingtip-boots.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 860, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <section style={{ overflow: 'hidden' }}>
      <div
        css={[
          container,
          css`
            padding-bottom: 64px;
            @media (min-width: ${breakpoints.medium}px) {
              padding-top: 80px;
            }
          `,
        ]}
      >
        <div
          css={css`
            @media (min-width: ${breakpoints.medium}px) {
              display: flex;
              flex-flow: row wrap;
              align-items: stretch;
            }
          `}
        >
          <div
            css={css`
              @media (min-width: ${breakpoints.medium}px) {
                position: relative;
                flex: 1 0 auto;
                min-height: 720px;
              }
            `}
          >
            <div
              css={css`
                @media (min-width: ${breakpoints.medium}px) {
                  position: absolute;
                  bottom: 0;
                  width: 720px;
                  right: -160px;
                }

                @media (min-width: ${breakpoints.large}px) {
                  width: 860px;
                  right: -120px;
                }
              `}
            >
              <Image
                {...hero.childImageSharp}
                alt="Grey Suede Wingtip Brogue Derby Boots"
              />
            </div>
          </div>
          <div
            css={css`
              position: relative;
              z-index: 10;
              max-width: 420px;
              margin-top: 40px;
              margin-left: auto;
              margin-right: auto;
              @media (min-width: ${breakpoints.medium}px) {
                margin-top: 0;
                margin-right: 16px;
              }
            `}
          >
            <h2 className="heading heading--h1" style={{ fontSize: '4.7rem' }}>
              Your shoes matter.
            </h2>
            <div>
              <p>
                A great suit deserves great shoes and the right footwear will
                transform your appearance whether you’re wearing jeans or a
                dinner suit.
              </p>
              <p>
                Fox Tailoring are proud partners with Undandy who traditionally
                craft footwear within their third-generation shoe workshop in
                São João da Madeira, Portugal.
              </p>
              <p>
                We can help you create completely customised shoes, boots or
                trainers that will complement your attire and are perfectly
                event-appropriate.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShoesIntro
