import React from 'react'
import Seo from '../components/SEO'

import Layout from '../components/layout'

import Hero from '../components/shoes/shoes-hero'
import UndandyBanner from '../components/shoes/undandy-banner'
import Intro from '../components/shoes/intro'
import MadeForYou from '../components/shoes/made-for-you'
import ShoePhotos from '../components/shoes/shoe-photos'
import Cta from '../components/shoes/cta'

const ShoesPage = () => (
  <Layout>
    <Seo
      title="Made to Order Shoes and Trainers — The Perfect Pairing for a Tailored Suit"
      description="A great suit deserves great shoes and the right footwear will transform your appearance whether you’re wearing jeans or a dinner suit."
      pathname="/custom-shoes/"
    />
    <Hero />
    <UndandyBanner />
    <Intro />
    <MadeForYou />
    <ShoePhotos />
    <Cta />
  </Layout>
)

export default ShoesPage

export const Head = () => <meta name="robots" content="noindex" />
