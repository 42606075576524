import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import Image from 'gatsby-image'
import { motion } from 'framer-motion'
import { breakpoints, colors, container } from '../../styles/theme'

const Hero = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "img/shoes/suede-toe-tan-oxfords.png" }) {
        childImageSharp {
          fluid(maxHeight: 600, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [heroLoaded, setHeroLoaded] = useState(false)

  return (
    <div
      css={css`
        background: linear-gradient(to bottom, #e2e2e2, #fff);
        box-shadow: 0 10px 60px 0 rgba(126, 126, 126, 0.6);
        padding: 40px 0 32px;
        overflow: hidden;
      `}
    >
      <motion.div
        css={container}
        animate={heroLoaded ? 'visible' : 'hidden'}
        initial="hidden"
      >
        <h1
          className="heading heading--h1"
          css={css`
            font-size: 4.6rem;
            white-space: nowrap;
            @media (min-width: ${breakpoints.medium}px) {
              font-size: 6rem;
            }
            @media (min-width: ${breakpoints.large}px) {
              margin-top: 40px;
            }
          `}
        >
          <motion.div
            variants={{
              hidden: { x: 30 },
              visible: { x: 0 },
            }}
            transition={{
              duration: 0.8,
            }}
          >
            Custom Footwear
          </motion.div>
          <strong
            css={css`
              margin-left: 0.7em;
              font-size: 0.9em;
              color: ${colors.darkGrey};
              display: flex;
            `}
          >
            <motion.div
              variants={{
                hidden: { x: -20, opacity: 0 },
                visible: { x: 0, opacity: 1 },
              }}
              transition={{
                delay: 0.7,
                duration: 1.1,
              }}
            >
              the perfect&nbsp;
            </motion.div>
            <motion.div
              variants={{
                hidden: { x: 20, opacity: 0 },
                visible: { x: 0, opacity: 1 },
              }}
              transition={{
                delay: 1.2,
                duration: 1.1,
              }}
            >
              pairing
            </motion.div>
          </strong>
        </h1>

        <motion.div
          variants={{
            hidden: { opacity: 0, scale: 0.98 },
            visible: { opacity: 1, scale: 1, x: 30 },
          }}
          transition={{ ease: 'easeOut', duration: 1.8 }}
          css={css`
            margin-right: -10%;
            @media (min-width: ${breakpoints.large}px) {
              margin-top: -80px;
            }
          `}
        >
          <Image
            onLoad={() => setHeroLoaded(true)}
            {...hero.childImageSharp}
            alt="Custom Suede Toe Cap Tan Oxford Brogue Shoes"
            style={{ maxHeight: '600px' }}
            imgStyle={{
              objectFit: 'contain',
              fontFamily: 'object-fit: contain',
            }}
          />
        </motion.div>
      </motion.div>
    </div>
  )
}

export default Hero
