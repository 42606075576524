import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/react'
import Image from 'gatsby-image'
import { container } from '../../styles/theme'

const ShoePhotos = () => {
  const {
    chocolateMonkStrap,
    greenSuedeLoafer,
    blueSuedeTrainers,
    greenWingtipSpectators,
  } = useStaticQuery(graphql`
    fragment shoePhoto on File {
      childImageSharp {
        fluid(maxWidth: 900, quality: 40) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    {
      chocolateMonkStrap: file(
        relativePath: {
          eq: "img/shoes/chocolate-suede-leather-monk-straps.jpg"
        }
      ) {
        ...shoePhoto
      }
      greenSuedeLoafer: file(
        relativePath: { eq: "img/shoes/green-suede-tassel-loafers.jpg" }
      ) {
        ...shoePhoto
      }
      blueSuedeTrainers: file(
        relativePath: { eq: "img/shoes/blue-suede-trainers.jpg" }
      ) {
        ...shoePhoto
      }
      greenWingtipSpectators: file(
        relativePath: { eq: "img/shoes/green-wingtip-spectators.jpg" }
      ) {
        ...shoePhoto
      }
    }
  `)

  return (
    <section style={{ overflow: 'hidden' }}>
      <div
        css={[
          container,
          css`
            padding-top: 120px;
            padding-bottom: 60px;
          `,
        ]}
      >
        <FeaturedShoe
          image={chocolateMonkStrap}
          alt="Chocolate Brown Suede and Leather Double Monk Strap Shoe"
          align="left"
        />
        <FeaturedShoe
          image={greenSuedeLoafer}
          alt="Apple Green Suede Tassle Loafer"
          align="right"
        />
        <FeaturedShoe
          image={blueSuedeTrainers}
          alt="Blue Suede Trainers with Navy Outsole"
          align="left"
        />

        <FeaturedShoe
          image={greenWingtipSpectators}
          alt="Green Wingtip Spectator Derby Shoe"
          align="center"
        />
      </div>
    </section>
  )
}

export default ShoePhotos

const FeaturedShoe = ({ alt, image, align = 'left' }) => {
  return (
    <div
      css={css`
        margin-top: 24px;
        margin-bottom: 24px;
        max-height: 600px;
        display: flex;
        justify-content: ${align === 'left'
          ? 'flex-start'
          : align === 'right'
          ? 'flex-end'
          : 'center'};
        ${align === 'left' && 'margin-left: -120px;'}
        ${align === 'right' && 'margin-right: -120px;'}
        ${align === 'center' &&
        `
          margin-top: 80px;
          margin-bottom: 80px;
          `}
      `}
    >
      <Image
        {...image.childImageSharp}
        alt={alt}
        style={{ flexBasis: '900px', maxHeight: '100%' }}
        imgStyle={{
          objectFit: 'contain',
          fontFamily: 'object-fit: cover; object-position: bottom',
        }}
      />
    </div>
  )
}
