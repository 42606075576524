import React from 'react'
import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import { motion, useViewportScroll, useTransform } from 'framer-motion'

const UndandyBanner = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(
        relativePath: { eq: "img/shoes/undandy-shoes-flatlay-bw.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600, maxHeight: 600, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const { scrollYProgress } = useViewportScroll()
  const yRange = [-50, 50]
  const y = useTransform(scrollYProgress, [0, 0.5], yRange)

  return (
    <div
      css={css`
        background: #000;
        height: 400px;
        overflow: hidden;
        position: relative;

        &::after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.15),
              rgba(0, 0, 0, 0) 24px
            ),
            linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0.4),
              rgba(255, 255, 255, 1)
            );
        }
      `}
    >
      <motion.div style={{ y }}>
        <Image
          {...hero.childImageSharp}
          alt="Undandy shoes flatlay at Fox Tailoring Bournemouth"
          style={{
            marginTop: '-50px',
            marginBottom: '-50px',
            height: '500px',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 1600,
          }}
        />
      </motion.div>
    </div>
  )
}

export default UndandyBanner
