import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/react'
import Image from 'gatsby-image'
import { breakpoints, colors, container } from '../../styles/theme'

const MadeForYou = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(
        relativePath: { eq: "img/shoes/patent-leather-wingtip-toe.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 420, quality: 40) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <section
      css={css`
        background: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.02),
            rgba(0, 0, 0, 0) 40px
          ),
          ${colors.lightGrey};
        border-bottom: 1px solid ${colors.white};
      `}
    >
      <div css={container}>
        <div
          css={css`
            @media (min-width: ${breakpoints.medium}px) {
              display: flex;
              justify-content: space-around;
              flex-flow: row wrap;
              align-items: center;
            }
          `}
        >
          <div
            css={css`
              max-width: 420px;
              flex: 0 0 420px;
              padding-top: 54px;
              padding-bottom: 54px;
              margin-left: auto;
              margin-right: auto;
              @media (min-width: ${breakpoints.medium}px) {
                margin-left: 16px;
                margin-right: 16px;
              }
            `}
          >
            <h2 className="heading heading--h1" style={{ fontSize: '5rem' }}>
              Made for you.
            </h2>
            <div>
              <p>
                Footwear can be ordered in a variety of lasts and styles,
                offering enhanced fit and the appearance you desire.
              </p>
              <p>
                Customise every detail with a wide variety of calf leathers,
                suedes and finishes. Create a bold unique look with contrasting
                colours or choose an elegant, antiqued aesthetic from a
                skillfully hand-applied patina finish.
              </p>
              <p>
                Fox Tailoring have a selection of shoes available to view in our
                showroom and we will be happy to guide you through the exciting
                process of creating a pair of custom shoes.
              </p>
            </div>
          </div>
          <div
            css={css`
              flex: 0 0 50%;
              @media (min-width: ${breakpoints.large}px) {
                margin-top: -40px;
              }
            `}
          >
            <Image
              {...hero.childImageSharp}
              alt="Patent Leather Brown and Tan Wing Tip Spectator Toe"
              style={{ maxWidth: 420, marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MadeForYou
